<template>
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img
            src="../../assets/images/manage/DiagramaCDS.png"
            class="img-fluid fadeIn animated wow center-block img-short"
            data-wow-delay=".2s"
          />
        </div>
        <div class="col-lg-6">
          <div class="feature-detail">
            <div class="title-box">
              <h2 class="fadeIn animated wow" data-wow-delay=".1s">KURLABS CDS</h2>
              <div class="border"></div>
            </div>

            <li class="my-3">{{$t("Our clients make a request.")}}</li>

            <li class="my-3">{{$t("Support reports the request to the project manager.")}}</li>

            <li class="my-3">{{$t("The director transmits the request to the development team.")}}</li>
            <li class="my-3">{{$t("The Development Team encodes the best request.")}}</li>
            <li class="my-3">{{$t("The code is uploaded to gitlab.")}}</li>
            <li class="my-3">{{$t("Gitlab automatically runs the tests and builds a new image.")}}</li>
            <li
              class="my-3"
            >{{$t("When passing the tests, the new code in production is displayed.")}}</li>
            <li class="my-3">{{$t("The end user can see the changes made.")}}</li>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Initial"
};
</script>
